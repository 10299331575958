import React from 'react'
import '../styles/Navbar2.scss'
import { pagesList } from '../datas/pagesList.js'
import { Link } from 'react-router-dom'
import Login from './Login.jsx'
const Navbar2 = () => {
  return (
    <nav id="navbar" style={{ zIndex: 2 }}>
      <ul className="navbar-items flexbox-col">
        {pagesList.map((page) =>
          page.visible ? (
            <li className="navbar-item flexbox-left" key={page.script}>
              <Link className="navbar-item-inner flexbox-left" to={page.script}>
                <div className="navbar-item-inner-icon-wrapper flexbox">{page.icon}</div>
                <span className="link-text">{page.intitule}</span>
              </Link>
            </li>
          ) : null
        )}
      </ul>
      <ul className="navbar-items flexbox-col">
        <Login />
      </ul>
    </nav>
  )
}

export default Navbar2
