import {
  CheckSquareOutlined,
  FileAddOutlined,
  FileOutlined,
  PieChartOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons'

export const pagesList = [
  {
    categorie: 'CarnetDeLiaison',
    intitule: 'Encodage du carnet de liaison',
    icon: <FileAddOutlined />,
    script: 'CarnetDeLiaisonEncodage',
    visible: true,
  },
  {
    categorie: 'CarnetDeLiaison',
    intitule: 'Lecture du carnet de liaison',
    icon: <FileOutlined />,
    script: 'CarnetDeLiaisonLecture',
    visible: true,
  },

  {
    categorie: 'Absences',
    intitule: 'Encodage des absences',
    icon: <CheckSquareOutlined />,
    script: 'AbsencesEncodage',
    visible: true,
  },
  {
    categorie: 'Absences',
    intitule: 'Lecture des statistiques',
    icon: <PieChartOutlined />,
    script: 'AbsencesLecture',
    visible: false,
  },
  {
    categorie: 'prof',
    intitule: 'Mes infos',
    icon: <UserOutlined />,
    script: 'MesInfos',
    visible: true,
  },
  {
    categorie: 'parametres',
    intitule: 'Paramètres',
    icon: <SettingOutlined />,
    script: 'Parametres',
    visible: true,
  },
]
