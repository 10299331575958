import { EditOutlined } from '@ant-design/icons'
import { Flex, Modal, QRCode } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

const NavBarJeuneLink = ({
  value,
  contenu,
  copyValue = null,
  icon,
  editLink = false,
  link,
  action = null,
  qrcode = false,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const elementStyle = { padding: 2 }
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Copié dans le presse-papiers !')
      })
      .catch((err) => {
        toast.error('Erreur lors de la copie.')
        console.error('Erreur de copie :', err)
      })
  }
  return value ? (
    <li style={elementStyle}>
      <Flex justify="space-between">
        <Flex className="itemType">
          {icon}
          {copyValue ? (
            <span onClick={() => (qrcode ? setOpenModal(true) : copyToClipboard(copyValue))} className="link_normal">
              {value ? contenu : '/'}
            </span>
          ) : (
            <Link onClick={action} className="itemAction" to={link}>
              <span className="link_normal">{value ? contenu : '/'}</span>
            </Link>
          )}
        </Flex>
        {editLink ? (
          <Link className="itemAction" to={link}>
            <EditOutlined style={{ fontSize: 12 }} />
          </Link>
        ) : null}
      </Flex>
      {qrcode ? (
        <Modal open={openModal} width={400} onCancel={() => setOpenModal(false)} footer={[]}>
          <Flex justify="center">
            <a href={`tel:${value}`} target="_blank" rel="noreferrer">
              <QRCode errorLevel="H" type="svg" size={300} value={`tel:${value}`} icon={icon} />
            </a>
          </Flex>
        </Modal>
      ) : null}
    </li>
  ) : null
}

export default NavBarJeuneLink
