import React, { useEffect, useState } from 'react'
import Titre from '../components/Titre.jsx'
import { api, apiDelete, apiPost, apiPut } from '../functions/api.js'
import { useCookies } from 'react-cookie'
import {
  Button,
  Collapse,
  ConfigProvider,
  DatePicker,
  Flex,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Spin,
  Table,
  Typography,
} from 'antd'

import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import dayjs from 'dayjs'
import locale from 'antd/locale/fr_BE'
import 'dayjs/locale/fr.js'
import ParametresDates from '../components/ParametresDates.jsx'
import ParametresAbsences from '../components/ParametresAbsences.jsx'

dayjs.locale('fr_BE')
const dateFormat = 'DD/MM/YYYY'
const dateTimeFormat = 'DD/MM/YYYY HH:mm'

// Imports nécessaires...

const MesInfos = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token', 'cours_fav'])
  const [prof, setProf] = useState(null)
  const [loading, setLoading] = useState(true)
  const [cours, setCours] = useState(null)
  const [coursSansFav, setCoursSansFav] = useState([])
  const [coursSansCoursId, setCoursSansCoursId] = useState([])

  const fetchProf = async () => {
    try {
      const response = await api(`prof/find/${cookies.id}`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      const leProf = {
        ...response.data,
        cours_id: response.data.cours_id ? JSON.parse(response.data.cours_id) : [], // Remplace null par un tableau vide
        cours_fav: response.data.cours_fav || null, // Remplace undefined par null
      }
      setProf(leProf)
    } catch (error) {
      console.error('Erreur lors de la récupération des données du prof:', error)
    } finally {
      setLoading(false)
    }
  }

  const fetchCours = async () => {
    try {
      const response = await api(`cours/allSort/all/${cookies.id}`, {
        id: cookies['id'],
        token: cookies['token'],
      })
      setCours(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des cours:', error)
    }
  }
  const saveProf = async (profToUpdate) => {
    try {
      //toast.dismiss()
      await toast.promise(
        // La promesse envoyée à l'API
        apiPost(
          `prof/update/${cookies.id}`,
          {
            id: cookies['id'],
            token: cookies['token'],
          },
          profToUpdate
        ),
        {
          pending: 'Envoi des données en cours...', // Message pendant le chargement
          success: 'Données sauvegardées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la sauvegarde : ${data?.response?.data?.message}` || 'Veuillez réessayer.'
            },
          }, // Message d'erreur
        }
      )
    } catch (error) {
      console.error('Erreur lors de la récupération des profs:', error)
    }
  }
  useEffect(() => {
    fetchCours()
    fetchProf()
  }, [])

  useEffect(() => {
    if (cours && prof) {
      const filteredCoursSansFav = cours.filter((c) => c.value !== prof.cours_fav)
      setCoursSansFav(filteredCoursSansFav)

      const filteredCoursSansCoursId = cours.filter((c) => !(prof.cours_id || []).includes(c.value))
      setCoursSansCoursId(filteredCoursSansCoursId)
    }
  }, [cours, prof])

  useEffect(() => {
    const leProf = prof
    saveProf(leProf)
  }, [prof])

  return (
    <div style={{ minHeight: '100%', padding: '20px 60px' }}>
      <Titre>Mes informations</Titre>
      <div className="Fond">
        {loading ? (
          <Spin />
        ) : (
          <Flex vertical>
            <Space direction="vertical" style={{ width: '100%', marginBottom: 15 }}>
              <Typography.Title
                style={{
                  color: '#6a778e',
                }}
                level={5}
              >
                Adresse email
              </Typography.Title>
              <Input
                defaultValue={prof ? prof.email : null}
                disabled
                onBlur={(e) => {
                  setProf({ ...prof, email: e.target.value })
                }}
              />
            </Space>
            <Flex justify="space-between" style={{ marginBottom: 15 }}>
              <Space direction="vertical" style={{ width: '49%' }}>
                <Typography.Title
                  style={{
                    color: '#6a778e',
                  }}
                  level={5}
                >
                  Prénom
                </Typography.Title>
                <Input
                  defaultValue={prof ? prof.prenom : null}
                  onBlur={(e) => {
                    setProf({ ...prof, prenom: e.target.value })
                  }}
                />
              </Space>
              <Space direction="vertical" style={{ width: '49%' }}>
                <Typography.Title
                  style={{
                    color: '#6a778e',
                  }}
                  level={5}
                >
                  Nom
                </Typography.Title>
                <Input
                  defaultValue={prof ? prof.nom : null}
                  onBlur={(e) => {
                    setProf({ ...prof, nom: e.target.value })
                  }}
                />
              </Space>
            </Flex>
            {cours ? (
              <Flex justify="space-between">
                <Space direction="vertical" style={{ width: '49%' }}>
                  <Typography.Title style={{ color: '#6a778e' }} level={5}>
                    Le cours par défaut
                  </Typography.Title>
                  <Select
                    options={coursSansCoursId}
                    defaultValue={prof ? prof.cours_fav : null}
                    onChange={(e) => {
                      setProf({ ...prof, cours_fav: e })
                    }}
                    style={{ width: '100%' }}
                  />
                </Space>
                <Space direction="vertical" style={{ width: '49%' }}>
                  <Typography.Title style={{ color: '#6a778e' }} level={5}>
                    Les cours à mettre en avant
                  </Typography.Title>
                  <Select
                    mode="multiple"
                    options={coursSansFav}
                    defaultValue={prof ? prof.cours_id : null}
                    onChange={(e) => {
                      setProf({ ...prof, cours_id: e })
                    }}
                    style={{ width: '100%' }}
                  />
                </Space>
              </Flex>
            ) : null}
          </Flex>
        )}
      </div>
    </div>
  )
}

export default MesInfos
