import React, { useEffect, useRef, useState } from 'react'
import Titre from '../components/Titre.jsx'
import '../styles/CarnetDeLiaisonEncodage.scss'

import { api, apiPost } from '../functions/api.js'
import { useCookies } from 'react-cookie'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import 'ckeditor5/ckeditor5.css'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Italic,
  Mention,
  Paragraph,
  Undo,
  List,
  Underline,
  Strikethrough,
  Highlight,
  Alignment,
  Link,
  Font,
} from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'
import { Form, Button, Flex, Input, DatePicker, Space, Typography, ConfigProvider, Select } from 'antd'
import dayjs from 'dayjs'

import locale from 'antd/locale/fr_BE'
import 'dayjs/locale/fr.js'
import { toast } from 'react-toastify'
dayjs.locale('fr_BE')
const dateFormat = 'DD/MM/YYYY'

const CarnetDeLiaisonEncodage = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token', 'cours_fav'])
  const formDataInitial = {
    date: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    prof: cookies['id'],
    jeunes: JSON.stringify([]),
    contexte: JSON.stringify([cookies['cours_fav']]),
    intitule: '',
    commentaire: '',
  }
  const [commentaire, setCommentaire] = useState('')
  const [jeunes, setJeunes] = useState([])
  const [jeunesRef, setJeunesRef] = useState([])
  const [jeunesHidden, setJeunesHidden] = useState([])
  const [cours, setCours] = useState([[], [], []])
  const [profs, setProfs] = useState([])
  const [formData, setFormData] = useState(formDataInitial)
  //const { quill, quillRef } = useQuill()
  const editorRef = useRef(null)

  const [form] = Form.useForm()

  useEffect(() => {
    fetchJeunes()
    fetchCours()
    fetchProfs()
  }, [])

  const fetchJeunes = async () => {
    try {
      const response = await api(`jeune/allSort/prof/` + cookies['id'], {
        id: cookies['id'],
        token: cookies['token'],
      })

      setJeunes(response.data.items)
      setJeunesHidden(response.data.hiddenItems)
      setJeunesRef(response.data.sorts)
    } catch (error) {
      console.error('Erreur lors de la récupération des jeunes:', error)
    }
  }
  const fetchCours = async () => {
    try {
      const response = await api(`cours/allSort/prof/` + cookies['id'], {
        id: cookies['id'],
        token: cookies['token'],
      })

      setCours(response.data.items)
    } catch (error) {
      console.error('Erreur lors de la récupération des cours:', error)
    }
  }
  const fetchProfs = async () => {
    try {
      const response = await api(`prof/allSort/` + cookies['id'], {
        id: cookies['id'],
        token: cookies['token'],
      })
      setProfs(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des profs:', error)
    }
  }
  const onFinish = async () => {
    try {
      const response = await apiPost(
        'carnetliaison/create',
        {
          id: cookies['id'],
          token: cookies['token'],
          'Content-Type': 'application/json',
        },
        formData
      )
      if (response.status === 200) {
        toast.success(response.data.message)
        form.resetFields() // Réinitialise les champs du formulaire
        setFormData({
          date: dayjs().format(dateFormat),
          prof: cookies['id'],
          jeunes: JSON.stringify([]),
          contexte: JSON.stringify([cookies['cours_fav']]),
          intitule: '',
          commentaire: '',
        })
        editorRef.current.editor.setData('')
        /*
        if (quill) {
          quill.setContents('') // Réinitialiser le contenu de Quill
        }
          */
      } else {
        console.error('Erreur:', response.data.message)
        toast.error(response.data.message)
      }
    } catch (error) {
      console.error('Failed to submit form:', error)
    }
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <div className="CarnetDeLiaisonEncodage">
      <Titre>Encodage d'une note</Titre>
      <Form
        form={form}
        name="basic"
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.2) 0 4px 2px -2px',
          background: 'white',
          borderRadius: 5,
          padding: '20px 4%',
          color: '#6a778e',
        }}
        initialValues={{ formDataInitial }}
        onFinish={onFinish}
      >
        <Flex justify={'space-between'} align={'center'}>
          <Space direction="vertical" style={{ width: '47%' }}>
            <Typography.Title
              style={{
                color: '#6a778e',
              }}
              level={3}
            >
              La date
            </Typography.Title>
            <ConfigProvider locale={locale}>
              <Form.Item name="date">
                <DatePicker
                  style={{ width: '100%' }}
                  size="large"
                  defaultValue={dayjs()}
                  value={dayjs()}
                  format={dateFormat}
                  onChange={(e) => {
                    setFormData((prevFormData) => ({
                      ...prevFormData,
                      date: dayjs(e).format('YYYY-MM-DD HH:mm:ss'),
                    }))
                  }}
                />
              </Form.Item>
            </ConfigProvider>
          </Space>
          <Space direction="vertical" style={{ width: '47%' }}>
            <Typography.Title
              style={{
                color: '#6a778e',
              }}
              level={3}
            >
              L'auteur
            </Typography.Title>
            <Form.Item name="prof">
              <Select
                style={{ width: '100%' }}
                size="large"
                options={profs}
                defaultValue={cookies.id}
                value={cookies.id}
                onChange={(e) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    prof: e,
                  }))
                }}
              />
            </Form.Item>
          </Space>
        </Flex>
        <Flex justify={'space-between'} align={'center'} style={{ marginTop: 20 }}>
          <Space direction="vertical" style={{ width: '47%' }}>
            <Typography.Title
              style={{
                color: '#6a778e',
              }}
              level={3}
            >
              Quels jeunes ?
            </Typography.Title>
            <Form.Item name="jeunes">
              <Select
                mode="multiple"
                style={{ width: '100%' }}
                size="large"
                placeholder="Quels jeunes ?"
                multiple
                options={[
                  {
                    value: 'refs',
                    label: <span>Jeunes référés</span>,
                    options: jeunesRef,
                  },
                  {
                    value: 'actifs',
                    label: <span>Jeunes actifs</span>,
                    options: jeunes,
                  },
                  {
                    value: 'anciens',
                    label: <span>Anciens jeunes</span>,
                    options: jeunesHidden,
                  },
                ]}
                onChange={(e) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    jeunes: JSON.stringify(e),
                  }))
                }}
              />
            </Form.Item>
          </Space>
          <Space direction="vertical" style={{ width: '47%' }}>
            <Typography.Title
              style={{
                color: '#6a778e',
              }}
              level={3}
            >
              Quels contextes ?
            </Typography.Title>
            <Form.Item name="contexte">
              <Select
                style={{ width: '100%' }}
                size="large"
                placeholder="Quels contextes ?"
                defaultValue={cookies.cours_fav}
                multiple
                options={[...cours[0], ...cours[1], ...cours[2]]}
                onChange={(e) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    contexte: JSON.stringify([e]),
                  }))
                }}
              />
            </Form.Item>
          </Space>
        </Flex>
        <Flex justify={'start'} align={'center'} style={{ marginTop: 20 }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Title
              style={{
                color: '#6a778e',
              }}
              level={3}
            >
              Quel intitulé ?
            </Typography.Title>
            <Form.Item name="intitule">
              <Input
                size="large"
                placeholder="Quel intitulé ?"
                onChange={(e) => {
                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    intitule: e.target.value,
                  }))
                }}
              />
            </Form.Item>
          </Space>
        </Flex>
        <Flex justify={'start'} align={'center'} style={{ marginTop: 20 }}>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Typography.Title
              style={{
                color: '#6a778e',
              }}
              level={3}
            >
              Quel commentaire ?
            </Typography.Title>
            <div className="commentaire__box">
              <CKEditor
                ref={editorRef}
                editor={ClassicEditor}
                config={{
                  toolbar: {
                    items: [
                      'heading', // Titres
                      'fontSize', // Taille de texte
                      'bold', // Gras
                      'italic', // Italique
                      'underline', // Souligné
                      'strikethrough', // Barré
                      'highlight', // Surligné
                      '|',
                      'bulletedList', // Liste non ordonnée (ul)
                      'numberedList', // Liste ordonnée (ol)
                      '|',
                      'alignment', // Alignement de texte
                      'link', // Liens
                      'imageUpload', // Image
                      'undo', // Annuler
                      'redo', // Répéter
                    ],
                  },
                  plugins: [
                    Bold,
                    Italic,
                    Underline,
                    Strikethrough,
                    Font,
                    Highlight,
                    Paragraph,
                    List,
                    Alignment,
                    Link,
                    Image,
                  ],
                  fontSize: {
                    options: ['tiny', 'small', 'default', 'big', 'huge'],
                  },
                  alignment: {
                    options: ['left', 'center', 'right', 'justify'],
                  },
                  highlight: {
                    options: [
                      {
                        model: 'yellowMarker',
                        class: 'marker-yellow',
                        title: 'Yellow marker',
                        color: 'var(--ck-highlight-marker-yellow)',
                        type: 'marker',
                      },
                      {
                        model: 'greenMarker',
                        class: 'marker-green',
                        title: 'Green marker',
                        color: 'var(--ck-highlight-marker-green)',
                        type: 'marker',
                      },
                      {
                        model: 'pinkMarker',
                        class: 'marker-pink',
                        title: 'Pink marker',
                        color: 'var(--ck-highlight-marker-pink)',
                        type: 'marker',
                      },
                    ],
                  },
                  initialData: commentaire,
                }}
                onChange={(e, editor) => {
                  const commentaire = editor.data.get()

                  setFormData((prevFormData) => ({
                    ...prevFormData,
                    commentaire: commentaire,
                  }))
                }}
              />
            </div>
          </Space>
        </Flex>

        <Flex justify={'end'} align={'center'} style={{ marginTop: 20 }}>
          <Space direction="vertical">
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Envoyer
              </Button>
            </Form.Item>
          </Space>
        </Flex>
      </Form>
    </div>
  )
}

export default CarnetDeLiaisonEncodage
