import React, { useEffect, useState } from 'react'
import { formaterTelephone } from '../functions/telephoneUtils'
import { Link } from 'react-router-dom'
import {
  CalendarOutlined,
  CheckOutlined,
  ClearOutlined,
  CopyOutlined,
  EditOutlined,
  FileDoneOutlined,
  HourglassOutlined,
  MailOutlined,
  MedicineBoxOutlined,
  PhoneOutlined,
  TeamOutlined,
} from '@ant-design/icons'
import '../styles/NavbarJeunes.scss'
import { Divider, Flex } from 'antd'
import { toast } from 'react-toastify'
import NavBarJeuneLink from './NavBarJeuneLink'
import dayjs from 'dayjs'
import { useCookies } from 'react-cookie'
import { api, apiPost } from '../functions/api'
const NavBarJeune = ({ jeune, fetchJeunes }) => {
  const [cookies, setCookie, removeCookie] = useCookies(['id', 'token', 'cours_fav'])
  const [profs, setProfs] = useState([])
  const dividerStyle = { color: '#808080', borderColor: '#808080' }
  const updateArchive = async (id, value) => {
    try {
      await toast.promise(
        // La promesse envoyée à l'API
        apiPost(
          `jeune/update/${id}`,
          {
            id: cookies['id'],
            token: cookies['token'],
          },
          { archive: value }
        ),
        {
          pending: 'Envoi des données en cours...', // Message pendant le chargement
          success: 'Données sauvegardées avec succès 👌', // Message de succès
          error: {
            render({ data }) {
              // Retourne un message d'erreur basé sur la réponse de l'API
              return `Erreur lors de la sauvegarde : ${data?.response?.data?.message || 'Veuillez réessayer.'}`
            },
          }, // Message d'erreur
        }
      )
    } catch (error) {
      console.error('Erreur lors de la sauvegarde:', error)
    } finally {
      fetchJeunes()
    }
  }
  const fetchProfs = async () => {
    try {
      const response = await api(`prof/allSort/` + cookies['id'], {
        id: cookies['id'],
        token: cookies['token'],
      })
      setProfs(response.data)
    } catch (error) {
      console.error('Erreur lors de la récupération des profs:', error)
    }
  }
  const getProfName = (id) => {
    const prof = profs.find((prof) => prof.value === id)
    return prof ? prof.label : 'Professeur inconnu'
  }
  useEffect(() => {
    fetchProfs()
  }, [])
  return (
    <ul style={{ color: 'white', listStyle: 'none', marginLeft: 16 }}>
      <Divider orientation="left" style={dividerStyle}>
        Tuteurs
      </Divider>
      {jeune.tuteur
        ? JSON.parse(jeune.tuteur).map((tut) => (
            <>
              <NavBarJeuneLink
                contenu={`${tut.role}: ${tut.email}`}
                value={tut.email}
                icon={<MailOutlined />}
                copyValue={tut.email}
                editLink={true}
                link={`jeune/${jeune.id}/2`}
                key={`email_${tut.id}_${jeune.id}`}
              />
              <NavBarJeuneLink
                value={tut.telephone}
                contenu={`${tut.role}: ${tut.telephone ? formaterTelephone(tut.telephone, 'xxxx/xx.xx.xx') : null}`}
                icon={<PhoneOutlined />}
                qrcode={true}
                copyValue={tut.telephone ? formaterTelephone(tut.telephone, 'xxxx/xx.xx.xx') : null}
                editLink={true}
                link={`jeune/${jeune.id}/2`}
                key={`telephone_${tut.id}_${jeune.id}`}
              />
            </>
          ))
        : null}
      <Divider orientation="left" style={dividerStyle}>
        Jeune
      </Divider>
      <NavBarJeuneLink
        value={jeune.telephone}
        contenu={formaterTelephone(jeune.telephone, 'xxxx/xx.xx.xx')}
        icon={<PhoneOutlined />}
        qrcode={true}
        editLink={true}
        copyValue={formaterTelephone(jeune.telephone, 'xxxx/xx.xx.xx')}
        link={`jeune/${jeune.id}/1`}
      />
      <NavBarJeuneLink
        value={jeune.email}
        contenu={jeune.email}
        icon={<MailOutlined />}
        copyValue={jeune.email}
        editLink={true}
        link={`jeune/${jeune.id}/1`}
      />
      <NavBarJeuneLink
        value={jeune.referent_id}
        contenu={getProfName(jeune.referent_id)}
        icon={<TeamOutlined />}
        editLink={true}
        link={`jeune/${jeune.id}/4`}
      />
      <NavBarJeuneLink
        value={jeune.pedopsy_id}
        contenu={jeune.pedopsy_id === 1 ? 'Stéphanie Delmarque' : 'Gwendoline Goossens'}
        icon={<MedicineBoxOutlined />}
        editLink={true}
        link={`jeune/${jeune.id}/4`}
      />
      <NavBarJeuneLink
        value="datesEntreeSortie"
        contenu={`Du ${dayjs(jeune.date_entree).format('DD/MM/YYYY')} au ${dayjs(jeune.date_sortie).format(
          'DD/MM/YYYY'
        )}`}
        icon={<CalendarOutlined />}
        editLink={true}
        link={`jeune/${jeune.id}/4`}
      />
      <NavBarJeuneLink
        icon={<FileDoneOutlined />}
        value="dateBilan"
        contenu={`Date du prochain bilan: ${
          jeune.bilans ? dayjs(jeune.bilans[jeune.bilans.length - 1].date).format('DD/MM/YYYY') : '???'
        }`}
        editLink={true}
        link={`jeune/${jeune.id}/4`}
      />
      <Divider orientation="left" style={dividerStyle}>
        Actions
      </Divider>
      <NavBarJeuneLink value="Editer" contenu="Editer" icon={<EditOutlined />} link={`jeune/${jeune.id}/1`} />
      {jeune.archive !== 1 ? (
        <NavBarJeuneLink
          value="actif"
          contenu="Rendre actif"
          icon={<CheckOutlined />}
          link={`jeune/${jeune.id}/1`}
          action={() => updateArchive(jeune.id, 1)}
        />
      ) : null}
      {jeune.archive !== 2 ? (
        <NavBarJeuneLink
          value="En attente"
          contenu="Mettre en attente"
          icon={<HourglassOutlined />}
          link={`jeune/${jeune.id}/1`}
          action={() => updateArchive(jeune.id, 2)}
        />
      ) : null}
      {jeune.archive !== 3 ? (
        <NavBarJeuneLink
          value="Archiver"
          contenu="Archiver"
          icon={<ClearOutlined />}
          link={`jeune/${jeune.id}/1`}
          action={() => updateArchive(jeune.id, 3)}
        />
      ) : null}
    </ul>
  )
}

export default NavBarJeune
